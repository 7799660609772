/**
 * Return the index of the nth occurence of string pat in the string str
 * @param str string that is processed
 * @param pat string that is searched for
 * @param n nth occurence
 */
export function nthIndex(str: string, pat: string, n: number): number {
  const L = str.length
  let i = -1
  while (n-- && i++ < L) {
    i = str.indexOf(pat, i)
    if (i < 0) break
  }
  return i
}

/** Function that count occurrences of a substring in a string;
 * @param string               The string
 * @param subString            The sub string to search for
 * @param allowOverlapping
 */
export function countOccurrences(string: string, subString: string, allowOverlapping?: boolean): number {
  string += ''
  subString += ''
  if (subString.length <= 0) return string.length + 1

  const step = allowOverlapping ? 1 : subString.length
  let n = 0,
    pos = 0

  // eslint-disable-next-line no-constant-condition
  while (true) {
    pos = string.indexOf(subString, pos)
    if (pos >= 0) {
      ++n
      pos += step
    } else break
  }
  return n
}

/**
 * Filters answer text and returns dialog name.
 * Returns dialog name or null.
 * @deprecated since 06.11.2023
 * @param {String} answerText
 */
export function getDialogNameFromAnswerText(answerText: string | null): string | null {
  if (answerText === null) return null
  const triggerRegex = new RegExp(/(?:(convaise.intentTrigger\("))(.*)(?="\))/)
  const match = triggerRegex.exec(answerText)
  if (match !== null && match.length >= 3) return match[2]
  return null
}

/**
 * Takes a string that should be used to build a regex and escapes all special chars that need escaping
 * @param regexString
 */
export function escapeSpecialCharsForRegex(regexString: string): string {
  const charsThatNeedEscaping = '\\[](){}*+?|^$.'
  for (const char of charsThatNeedEscaping) {
    regexString = regexString.replace(char, '\\' + char)
  }
  return regexString
}

/**
 * Formats a given number with thousands separator.
 * Adds a '.' after 3 digits.
 * @param number
 */
export function formatNumberWithThousandsSeparator(number: number): string {
  if (typeof number === 'undefined') return '-'
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

/**
 * Extracts first occurance of uuid of a text.
 * If no uuid is found, returns null
 * @param text
 */
export function getUUIDFromString(text: string): string | null {
  const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i
  const result = text.match(UUID_REGEX)
  if (result !== null && result[0]) return result[0]
  return null
}

/**
 * Lowercases a string and removes all non-alphanumeric chars.
 * @param text
 */
export function lowercaseAndRemoveNonAlphaNumeric(text: string): string {
  const lowercased = text.toLowerCase()
  return lowercased.replace(/[^a-zöäüA-ZÖÄÜ0-9]/g, '')
}

const ALPHABETIC_CHAR_REGEX = /[a-zäöüßA-ZÖÄÜ]+/

/**
 * Returns true if provided string has alphabetical character. Returns false if not or empty string.
 * @param text
 */
export function hasAlphabeticChar(text: string): boolean {
  return ALPHABETIC_CHAR_REGEX.test(text)
}

/**
 * Replaces all occurances of find in text with replace
 * @param text
 * @param find
 * @param replace
 */
export function replaceAll(text: string, find: string, replace: string): string {
  const re = new RegExp(find, 'g')
  return text.replace(re, replace)
}

/**
 * Checks whether a given string is a valid URL.
 * @param text
 */
export function isValidUrl(text: string): boolean {
  try {
    new URL(text)
    return true
  } catch (err) {
    return false
  }
}

export function generateRandomIdString(length = 10): string {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

/**
 * Cleans up and converts mixed HTML and Markdown content to pure Markdown.
 *
 * This function performs the following operations:
 * 1. Removes Markdown code block indicators.
 * 2. Removes most HTML tags, preserving only specific ones for conversion.
 * 3. Converts HTML unordered lists to Markdown lists.
 * 4. Converts HTML tables to Markdown tables.
 * 5. Cleans up excessive whitespace and newlines.
 * 6. Replaces <br> lines with \
 * 7. Removes <!-- --> comments
 *
 * @param content - The input string containing mixed HTML and Markdown content.
 * @returns A cleaned-up string in pure Markdown format.
 */
export function cleanupMarkdown(content: string): string {
  // remove markdown indicator
  let markdown = content.replace(/```markdown\n?([\s\S]*?)\n?```/g, '$1')

  // Remove HTML tags, except for specific ones we want to convert
  markdown = markdown.replace(/<(?!\/?(ul|li|table|tr|td))[^>]+>/g, '')

  // Convert <ul> and <li> to Markdown list
  markdown = markdown.replace(/<\/?ul>/g, '\n')
  markdown = markdown.replace(/<li>/g, '- ')
  markdown = markdown.replace(/<\/li>/g, '\n')

  // Convert <table>, <tr>, and <td> to Markdown table
  markdown = markdown.replace(/<table>/g, '\n')
  markdown = markdown.replace(/<\/table>/g, '\n')
  markdown = markdown.replace(/<tr>/g, '|')
  markdown = markdown.replace(/<\/tr>/g, '|\n')
  markdown = markdown.replace(/<td>/g, ' ')
  markdown = markdown.replace(/<\/td>/g, ' |')

  // Clean up extra whitespace and newlines
  markdown = markdown.replace(/\n{3,}/g, '\n\n')
  markdown = markdown.trim()

  // Replace <br> lines with \
  markdown = markdown.replace(/^<br>$/gm, '\\')

  // Remove <!-- --> comments
  markdown = markdown.replace(/^<!-- -->$/gm, '')

  return markdown
}
